<template>
  <v-row title="">
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Permissions (Tenant-wide)</v-card-title>
        <v-card>
          <v-col
            cols="12"
            style="padding-bottom:0px; margin-bottom:0px"
          >
            <v-alert
              v-show="alert.show"
              :color="alert.color"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1 justify-right">
                    {{ alert.message }}
                  </p>
                  <a
                    align="right"
                    href="javascript:void(0)"
                    title="Hide this message"
                    :class="alert.class"
                    @click.prevent="hideAlert"
                  >
                    <span class="text-sm">{{ alert.callToAction }}</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-data-table
            :items="permissions"
            :headers="computedHeaders"
            :search="search"
            item-key="id"
            class="elevation-1"
            disable-pagination
            hide-default-footer
            xxx-sort-by="type"
            :sort-by="['label']"
            :sort-desc="[false, true]"
            xxx-multi-sort
            xxx-group-by="type"
            xxx-show-group-by
            title=""
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-icon class="text--primary me-3">
                    {{ icons.mdiMagnify }}
                  </v-icon>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="showUUIDs"
                  label="Detailed View"
                  class="mt-2"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  @click.prevent="createPermission"
                >
                  New Permission
                </v-btn>
              </v-toolbar>
              <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h6">
                    Are you sure you want to delete this item?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      outlined
                      text
                      @click="deleteItemConfirm"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      outlined
                      text
                      @click="closeDelete"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <!--
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
              -->
              <v-icon
                v-if="item.status !== 'LOCKED'"
                small
                class="mr-2"
                title="Details"
                @click="editItem(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
              <v-icon
                v-if="item.status !== 'LOCKED'"
                small
                title="Delete Permission"
                @click="deleteItem(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <template v-slot:item.status="{ item }">
              <span>{{ status[item.status] }}</span>
            </template>
            <template v-slot:item.created_at="{ item }">
              <span>{{ item.created_at ? new Date(item.created_at).toLocaleString() : '' }}</span>
            </template>
            <template v-slot:item.updated_at="{ item }">
              <span>{{ item.updated_at ? new Date(item.updated_at).toLocaleString() : '' }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiMagnify,
  mdiPencilOutline, mdiDeleteOutline,
  mdiEyeOutline,
} from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'
import gql from 'graphql-tag'
import { listPermissions } from '../../graphql/queries'
import { deletePermission } from '../../graphql/mutations'
import store from '../../store'

/* eslint-disable */
export default {
  name: 'Permissions',
  data() {
    return {
      search: '',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Permission ID',
          align: 'start',
          sortable: true,
          filterable: true,
          value: 'id',
        },
        {
          text: 'Permission Label',
          sortable: true,
          filterable: true,
          value: 'label',
        },
        {
          text: 'Value',
          sortable: true,
          filterable: true,
          value: 'value',
        },
        {
          text: 'Subtype',
          sortable: true,
          filterable: true,
          value: 'subtype',
        },
        /*
        {
          text: 'Type',
          sortable: true,
          filterable: true,
          value: 'type',
        },
        */
        {
          text: 'Status',
          sortable: true,
          filterable: true,
          value: 'status',
        },
        {
          text: 'Created at',
          sortable: true,
          filterable: true,
          value: 'created_at',
        },
        {
          text: 'Updated at',
          sortable: true,
          filterable: true,
          value: 'updated_at',
        },
        {
          text: 'Actions',
          sortable: false,
          filterable: false,
          value: 'actions',
        },
      ],
      permissions: [],
      permission: {
        label: '',
        type: '',
      },
    }
  },

  /**
   * computed
   */
  computed: {
    /**
     * computedHeaders
     */
    computedHeaders () {
      return this.headers.filter((h) => (!['id', 'created_at', 'updated_at'].includes(h.value)) || this.showUUIDs)
    },
  },


  /**
   * watch
   */
  watch: {
    showUUIDs() {
      localStorage.setItem('showUUIDs', this.showUUIDs)
    },
    tenant() {
      this.getTenants()
    },
    account() {
      this.getTenants()
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  /**
   * created
   */
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    this.getPermissions()
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const status = {
      'ENABLED': 'Enabled',
      'DISABLED': 'Disabled',
      'LOCKED': 'Locked',
    }
    const showUUIDs = localStorage.getItem('showUUIDs') ? localStorage.getItem('showUUIDs') === 'true' : false
    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      showUUIDs,
      status,
      loginStatus,
      icons: {
        mdiMagnify,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
      },
    }
  },

  /**
   * methods
   */
  methods: {
    async getPermissions() {
      const permissions = await store.getAppSyncClient().query({ query: gql(listPermissions), variables: { filter: { account: { eq: this.loginStatus.ten } }, limit: 1000 } })
      this.permissions = permissions.data.listPermissions.items
    },
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },
    hideAlert() {
      this.alert.show = false
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    editItem(item) {
      this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/permissions/${item.id}`)
    },
    deleteItem (item) {
      this.editedIndex = this.permissions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm () {
      await this.deletePermission(this.editedItem.id)
      this.closeDelete()
    },
    async deletePermission(id) {
      const deletion = await store.getAppSyncClient().mutate({ mutation: gql(deletePermission), variables: { input: { id: id } } })
      if (deletion.data) this.permissions.splice(this.editedIndex, 1)
      this.showAlert('Permission successfully deleted.', 'OK', 'success')
      this.getPermissions()
    },
    async createPermission() {
      this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/permissions/new`)
    },
  },
}
/* eslint-enable */
</script>
